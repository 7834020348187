import React, { useEffect, useState } from 'react';
import { NoshiComponent } from '../../components/noshi';
import {
  Box,
  BoxPrintSizeCombinations,
  NoshiKinds,
  Noshi,
  PrintSize,
} from '../../consts';

export type NoshiFormValues = {
  box: Box;
  kind: Noshi;
  familyName: string;
  naire1: string;
  naire2: string;
  naire3: string;
  usuzumi: boolean;
  title: string;
  titleCheck: boolean;
};

const initFormValues: NoshiFormValues = {
  box: '紙管L',
  kind: '紅白・花結（御祝）',
  familyName: '',
  naire1: '',
  naire2: '',
  naire3: '',
  usuzumi: false,
  title: '',
  titleCheck: false,
};

export type FontSizePosition = {
  all: number;
  naire1: number;
  naire2: number;
  naire3: number;
  x: number;
  y: number;
  titleSize?: number;
  titleTop: number;
  titleLeft: number;
};

// X軸
const DEFAULT_X = 50;
// Y軸
const DEFAULT_Y = 60;
// X最小
const LIMIT_X_MIN = 10;
// X最大
const LIMIT_X_MAX = 90;
// Y最小
const LIMIT_Y_MIN = 30;
// Y最大
const LIMIT_Y_MAX = 80;
// 移動量(%)
const MOVE = 0.5;
// 初期フォントサイズ(%)
const DEFAULT_FONT_SIZE = 100;
// 拡大率
const SCALE = 10;
// 最小拡大率
const SCALE_LIMIT_MIN = 20;
// 最大拡大率
const SCALE_LIMIT_MAX = 320;
// 表書き初期位置
const DEFAULT_TITLE_TOP = 0;
const DEFAULT_TITLE_LEFT = 0;

const initialFontSizePosition: FontSizePosition = {
  all: DEFAULT_FONT_SIZE,
  naire1: DEFAULT_FONT_SIZE,
  naire2: DEFAULT_FONT_SIZE,
  naire3: DEFAULT_FONT_SIZE,
  x: DEFAULT_X,
  y: DEFAULT_Y,
  titleSize: 24,
  titleTop: DEFAULT_TITLE_TOP,
  titleLeft: DEFAULT_TITLE_LEFT,
};

export const ControlKeys = [
  'all-reset',
  'all-small',
  'all-big',
  'title-reset',
  'title-small',
  'title-big',
  '1-small',
  '1-big',
  '2-small',
  '2-big',
  '3-small',
  '3-big',
] as const;
export type ControlKeyType = (typeof ControlKeys)[number];

export const MoveKeys = ['up', 'down', 'left', 'right', 'reset'] as const;
export type MoveKeyType = (typeof MoveKeys)[number];

export const NoshiContainer: React.FC = () => {
  const [fontSizePosition, setFontSizePosition] = useState<FontSizePosition>(
    initialFontSizePosition
  );

  const [formValues, setFormValues] = useState<NoshiFormValues>(initFormValues);
  const [printSize, setPrintSize] = useState<PrintSize>('A4');
  const [noshiClassName, setNoshiClassName] = useState<string>('');
  const [isOpenModal, setIsOpenModal] = useState<boolean>(true);
  const [showUsuzumi, setShowUsuzumi] = useState<boolean>(false);
  const [justReset, setJustReset] = useState<boolean>(true);

  useEffect(() => {
    if (!formValues.box || !formValues.kind) return;
    const combination = BoxPrintSizeCombinations.find((c) => {
      return c.box === formValues.box;
    });
    if (!combination) throw new Error('');
    setPrintSize(combination.printSize);
    let className = '';
    for (const key of Object.keys(NoshiKinds) as Noshi[]) {
      if (formValues.kind === key) {
        className = `${combination.classPrefix}${NoshiKinds[key]}`;
        break;
      }
    }
    setNoshiClassName(className);
    setJustReset(true);
  }, [formValues.box, formValues.kind]);

  const onClickTitle = (event: React.MouseEvent<HTMLButtonElement>) => {
    const name = event.currentTarget.name;
    if (name === 'size-small') {
      setJustReset(false);
      const h3Element = document.querySelector('.title h3');
      if (!h3Element) return;
      const computedStyle = window.getComputedStyle(h3Element);
      setFontSizePosition({
        ...fontSizePosition,
        titleSize: Math.max(1, parseFloat(computedStyle.fontSize) * 0.95),
      });
    } else if (name === 'size-big') {
      setJustReset(false);
      const h3Element = document.querySelector('.title h3');
      if (!h3Element) return;
      const computedStyle = window.getComputedStyle(h3Element);
      setFontSizePosition({
        ...fontSizePosition,
        titleSize: parseFloat(computedStyle.fontSize) * 1.05,
      });
    } else if (name === 'size-reset') {
      setJustReset(true);
      if (!formValues?.titleCheck) {
        const { titleSize, ...n } = {
          ...fontSizePosition,
        };
        titleSize;
        setFontSizePosition(n);
      }
    } else if (name === 'title-up') {
      setFontSizePosition({
        ...fontSizePosition,
        titleTop: fontSizePosition.titleTop - 2,
      });
    } else if (name === 'title-down') {
      setFontSizePosition({
        ...fontSizePosition,
        titleTop: fontSizePosition.titleTop + 2,
      });
    } else if (name === 'title-left') {
      setFontSizePosition({
        ...fontSizePosition,
        titleLeft: fontSizePosition.titleLeft - 4,
      });
    } else if (name === 'title-right') {
      setFontSizePosition({
        ...fontSizePosition,
        titleLeft: fontSizePosition.titleLeft + 4,
      });
    } else if (name === 'title-reset') {
      setFontSizePosition({
        ...fontSizePosition,
        titleTop: DEFAULT_TITLE_TOP,
        titleLeft: DEFAULT_TITLE_LEFT,
      });
    }
  };

  const onClickSize = (key: ControlKeyType) => {
    if (key === 'all-reset') {
      setFontSizePosition(initialFontSizePosition);
    } else if (key === 'all-small') {
      const all = fontSizePosition.all - SCALE;
      const naire1 = fontSizePosition.naire1 - SCALE;
      const naire2 = fontSizePosition.naire2 - SCALE;
      const naire3 = fontSizePosition.naire3 - SCALE;
      setFontSizePosition({
        ...fontSizePosition,
        all: all > SCALE_LIMIT_MIN ? all : fontSizePosition.all,
        naire1: naire1 > SCALE_LIMIT_MIN ? naire1 : fontSizePosition.naire1,
        naire2: naire2 > SCALE_LIMIT_MIN ? naire2 : fontSizePosition.naire2,
        naire3: naire3 > SCALE_LIMIT_MIN ? naire3 : fontSizePosition.naire3,
      });
    } else if (key === 'all-big') {
      const all = fontSizePosition.all + SCALE;
      const naire1 = fontSizePosition.naire1 + SCALE;
      const naire2 = fontSizePosition.naire2 + SCALE;
      const naire3 = fontSizePosition.naire3 + SCALE;
      setFontSizePosition({
        ...fontSizePosition,
        all: all < SCALE_LIMIT_MAX ? all : fontSizePosition.all,
        naire1: naire1 < SCALE_LIMIT_MAX ? naire1 : fontSizePosition.naire1,
        naire2: naire2 < SCALE_LIMIT_MAX ? naire2 : fontSizePosition.naire2,
        naire3: naire3 < SCALE_LIMIT_MAX ? naire3 : fontSizePosition.naire3,
      });
    } else if (key === '1-small') {
      const naire1 = fontSizePosition.naire1 - SCALE;
      if (naire1 > SCALE_LIMIT_MIN)
        setFontSizePosition({ ...fontSizePosition, naire1: naire1 });
    } else if (key === '1-big') {
      const naire1 = fontSizePosition.naire1 + SCALE;
      if (naire1 < SCALE_LIMIT_MAX)
        setFontSizePosition({ ...fontSizePosition, naire1: naire1 });
    } else if (key === '2-small') {
      const naire2 = fontSizePosition.naire2 - SCALE;
      if (naire2 > SCALE_LIMIT_MIN)
        setFontSizePosition({ ...fontSizePosition, naire2: naire2 });
    } else if (key === '2-big') {
      const naire2 = fontSizePosition.naire2 + SCALE;
      if (naire2 < SCALE_LIMIT_MAX)
        setFontSizePosition({ ...fontSizePosition, naire2: naire2 });
    } else if (key === '3-small') {
      const naire3 = fontSizePosition.naire3 - SCALE;
      if (naire3 > SCALE_LIMIT_MIN)
        setFontSizePosition({ ...fontSizePosition, naire3: naire3 });
    } else if (key === '3-big') {
      const naire3 = fontSizePosition.naire3 + SCALE;
      if (naire3 < SCALE_LIMIT_MAX)
        setFontSizePosition({ ...fontSizePosition, naire3: naire3 });
    } else {
      throw new Error('');
    }
  };

  const onClickMove = (key: MoveKeyType) => {
    if (key === 'reset') {
      setFontSizePosition({
        ...fontSizePosition,
        x: DEFAULT_X,
        y: DEFAULT_Y,
      });
    } else if (key === 'up') {
      const y = fontSizePosition.y - MOVE;
      if (y > LIMIT_Y_MIN) setFontSizePosition({ ...fontSizePosition, y });
    } else if (key === 'down') {
      const y = fontSizePosition.y + MOVE;
      if (y < LIMIT_Y_MAX) setFontSizePosition({ ...fontSizePosition, y });
    } else if (key === 'left') {
      const x = fontSizePosition.x - MOVE;
      if (x > LIMIT_X_MIN) setFontSizePosition({ ...fontSizePosition, x });
    } else if (key === 'right') {
      const x = fontSizePosition.x + MOVE;
      if (x < LIMIT_X_MAX) setFontSizePosition({ ...fontSizePosition, x });
    } else {
      throw new Error('');
    }
  };

  const onClickUpdate = () => {
    setIsOpenModal(false);
  };

  const onClickReset = () => {
    setFormValues(initFormValues);
    setShowUsuzumi(false);
    setIsOpenModal(false);
  };

  const onChangeForm = (
    event: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>
  ) => {
    const { name, value } = event.currentTarget;
    if (!Object.keys(initFormValues).includes(name)) throw new Error('');

    const newFormValues = { ...formValues, [name]: value };
    console.log('newFormValues', newFormValues);
    if (name === 'kind') {
      const isKokorozashi = value === '黒白・七本結切（志）';
      setShowUsuzumi(isKokorozashi);
      newFormValues.usuzumi = false;
    } else if (name === 'usuzumi') {
      const isChecked = (event.currentTarget as HTMLInputElement).checked;
      newFormValues.usuzumi = isChecked;
    } else if (name === 'titleCheck') {
      const isChecked = (event.currentTarget as HTMLInputElement).checked;
      newFormValues.titleCheck = isChecked;
    }
    setFormValues(newFormValues);
  };

  return (
    <NoshiComponent
      formValues={formValues}
      showUsuzumi={showUsuzumi}
      printSize={printSize}
      noshiClassName={noshiClassName}
      isOpenModal={isOpenModal}
      fontSizePosition={fontSizePosition}
      justReset={justReset}
      onOpenModal={() => setIsOpenModal(true)}
      onCloseModal={() => setIsOpenModal(false)}
      onClickTitle={onClickTitle}
      onClickSize={onClickSize}
      onClickMove={onClickMove}
      onClickUpdate={onClickUpdate}
      onClickReset={onClickReset}
      onChangeForm={onChangeForm}
    />
  );
};
